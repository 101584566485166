<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Chi tiết chương trình {{ " - " + programme_name }}
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <!--      <router-link :to="{name:'ProgrammeList'}">-->
      <!--        <v-btn  color="primary" class="mb-5" x-small large>-->
      <!--          <v-icon color="white" dark>mdi-keyboard-backspace</v-icon><span style="color: white">Programme List</span>-->
      <!--        </v-btn>-->
      <!--      </router-link>-->

      <div v-if="Object.keys(programme).length">
        <v-row>
          <v-col cols="6">
            <p class="text-h6 font-weight-bold">Thông tin các khóa học</p>
            <div class="text-right mb-2" style="width: 100%">
              <v-btn
                outlined
                color="cyan"
                class="mr-2"
                x-small
                large
                @click="openSortCourse"
              >
                <v-icon dark>mdi-sort</v-icon><span>Sort Course</span>
              </v-btn>
              <v-btn
                outlined
                color="#3699FF"
                class=""
                x-small
                large
                @click="openDialogAddCourse"
              >
                <v-icon dark>mdi-file</v-icon><span>Add Course</span>
              </v-btn>
            </div>
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Cover</th>
                    <th scope="col">Level</th>
                    <th scope="col">Total Hours</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(course, i) in programme.courses">
                    <tr :key="i">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.name.vi }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="course.cover"
                          :src="course.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.entry_level }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.end_of_course_level }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg text-center"
                        >
                          {{ course.total_hours }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'CourseContent',
                                  params: {
                                    id: course.id,
                                    courseName: course.name.en,
                                  },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Course Items</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="comfirmDeleteCourse(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete Course</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!programme.courses.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <p class="text-h6 font-weight-bold">Thông tin chương trình</p>
            <b-img
              class="mb-2"
              width="450"
              :src="programme.cover.vi"
              alt="Image 1"
              fluid
              rounded
            ></b-img>
            <div class="d-block text-body-1" v-if="programme.tag_line">
              <b>- Giá:</b> {{ formatNumber(programme.price) + " VND" }}
            </div>
            <div class="d-block text-body-1" v-if="programme.tag_line">
              <b>- Tag line:</b> {{ programme.tag_line.vi }}
            </div>
            <div class="d-block text-body-1 mb-1" v-if="programme.tag_line">
              <b>- Entry Level:</b> {{ programme.entry_level }}
            </div>
            <div class="d-block text-body-1 mb-1" v-if="programme.tag_line">
              <b>- Target:</b> {{ programme.target }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogSortCourse" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Course</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="courses">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(course, i) in courses"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #6993ff">
                          {{ course.name.vi }}
                        </h5>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.entry_level }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.end_of_course_level }}</span
                          >
                        </p>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="sortCourseProgramme"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogSortCourse = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogAddCourse" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Course</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Courses</label>
                  <v-autocomplete
                    v-model="course_ids"
                    :items="course_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.vi"
                    item-value="id"
                    multiple
                    auto-select-first
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddCourse"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddCourse = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ProgrammeDetail",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  // props: ['programme'],
  data() {
    return {
      is_call_api: false,
      dialogSortCourse: false,
      courses: [],
      course_tags: [],
      course_ids: [],
      isUpdating: false,
      dialogAddCourse: false,
      programme: {},
      programme_name: "",
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  created() {
    this.is_call_api = true;
    this.getProgramme();
    this.getAllCourse();
    this.is_call_api = false;
  },
  computed: {
    programme_id: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    async getProgramme() {
      let vm = this;
      await ApiService.get("prep-app/programme/" + vm.programme_id).then(
        function (response) {
          if (response.status == 200) {
            vm.programme = response.data;
            vm.programme_name = response.data.name.vi;
          }
        }
      );
    },
    openSortCourse() {
      this.courses = [...this.programme.courses];
      this.dialogSortCourse = true;
    },
    async sortCourseProgramme() {
      let self = this;
      let items = this.courses.map((course_item, index) => {
        return {
          item_id: course_item.id,
          offset: index + 1,
        };
      });
      let data = {
        items: items,
      };
      await ApiService.put(
        "prep-app/programme/" + self.programme.id + "/sorting-course",
        data
      )
        .then(function (res) {
          if (res.status == 202) {
            self.actionSortCouse(data.items);
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            self.dialogSortCourse = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    actionSortCouse(dataSort) {
      let data = [];
      for (let indexSort = 0; indexSort < dataSort.length; indexSort++) {
        for (
          let indexCourse = 0;
          indexCourse < this.programme.courses.length;
          indexCourse++
        ) {
          if (
            dataSort[indexSort].item_id ==
            this.programme.courses[indexCourse].id
          ) {
            data[indexSort] = this.programme.courses[indexCourse];
          }
        }
      }
      this.programme.courses = [...data];
    },
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status == 200) {
          vm.course_tags = response.data;
        }
      });
    },
    removeCourseTag(item) {
      let index = this.course_ids.indexOf(item.id);
      if (index >= 0) this.course_ids.splice(index, 1);
    },
    openDialogAddCourse() {
      this.course_ids = this.programme.courses.map((e) => {
        return e.id;
      });
      this.dialogAddCourse = true;
    },
    btnAddCourse() {
      let seft = this;
      seft.is_call_api = true;
      let formData = new FormData();
      formData.append("course_ids", this.course_ids);
      formData.append("programme_id", this.programme_id);
      ApiService.post("prep-app/programme/sync", formData)
        .then(function (res) {
          console.log(res);
          if (res.status == 202) {
            seft.getProgramme();
            seft.dialogAddCourse = false;
            seft.is_call_api = false;
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            seft.is_call_api = false;
            seft.$toasted.error(
              "Something Wrong. Please try again or contact admin !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
    comfirmDeleteCourse(indexCourse) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.btnDeleteProgrammeCourse(indexCourse);
        }
      });
    },
    btnDeleteProgrammeCourse(indexCourse) {
      let vm = this;
      let courses = [...this.programme.courses];
      courses.splice(indexCourse, 1);
      let course_ids = courses.map((e) => {
        return e.id;
      });
      let formData = new FormData();
      formData.append("course_ids", course_ids);
      formData.append("programme_id", this.programme_id);
      ApiService.post("prep-app/programme/sync", formData)
        .then(function (res) {
          if (res.status == 202) {
            vm.getProgramme();
            vm.dialogAddCourse = false;
            vm.is_call_api = false;
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            vm.$toasted.error(
              "Something Wrong. Please try again or contact admin !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },

    formatNumber(number) {
      return new Intl.NumberFormat().format(number);
    },
  },
};
</script>

<style lang="css" scoped>
.drag-css {
  cursor: pointer;
}
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.container-border {
  border: 1px solid #e0e0e0;
  border-radius: 1px;
}
.text-body-1 {
  font-size: 1.1rem !important;
}
</style>
